<template>
    <div class="profile first_page_margin">
      <div class="profile_user_info_block">
        <div class="profile_user_info_head">
          <div class="profile_user_info_head_left">
            <img class="" src="@/assets/default-avatar.png">
          </div>
          <div class="profile_user_info_head_content">
            <div class="user_name" v-html="user_name+'('+ user_tel +')'" style="padding-bottom: 10px;">
            </div>
            <div class="user_invite_code">
              {{$t('message.invite_code')}}
              <span v-html="user_invite_code"></span>
            </div>

            <div class="profile_user_info_end_item">
              <div class="title">{{$t('message.profile_all_money')}}</div>
              <div class="value" style="margin-top: 5px;">
                <span style="margin-right: 10px;">≈</span>
                <span v-html="all_money"></span>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="profile_user_action_block">
        <div class="action_row">
          <div class="action_item" @click="to_recharge">
            <img src="@/assets/my_deposit.png" />
            <div class="action_item_title">{{$t('message.profile_recharge')}}</div>
          </div>
          <div class="action_item">
            <img src="@/assets/my_withdraw.png" @click="to_withdrawal"/>
            <div class="action_item_title">{{$t('message.profile_withdrawal')}}</div>
          </div>
          <div class="action_item">
            <img src="@/assets/my_team.png" @click="to_transfer"/>
            <div class="action_item_title">{{$t('message.profile_transfer')}}</div>
          </div>
        </div>
        <div class="action_row">
          <div class="action_item" @click="to_account(0)">
            <img src="@/assets/my_bind.png" />
            <div class="action_item_title">{{$t('message.profile_exchange_account')}}</div>
          </div>
          <div class="action_item" @click="to_account(1)">
            <img src="@/assets/my_security.png"  />
            <div class="action_item_title">{{$t('message.profile_contract_account')}}</div>
          </div>
          <div class="action_item" @click="to_account(2)">
            <img src="@/assets/my_transaction.png"  />
            <div class="action_item_title">{{$t('message.profile_option_account')}}</div>
          </div>
        </div>
        <div class="action_row">
          <div class="action_item" @click="to_security">
            <img src="@/assets/my_security.png"/>
            <div class="action_item_title">{{$t('message.action_security_title')}}</div>
          </div>
          <div class="action_item" @click="to_notify">
            <img src="@/assets/my_notify.png"  />
            <div class="action_item_title">{{$t('message.profile_notification')}}</div>
          </div>
          <div class="action_item" @click="to_invite">
            <img src="@/assets/home_invite.png"/>
            <div class="action_item_title">{{$t('message.profile_invite')}}</div>
          </div>
        </div>
      </div>

      <div class="profile_user_logout" style="margin-bottom: 10px;">
         <div class="danger_btn btn_shape" style="" @click="to_logout">{{$t('message.profile_logout')}}</div>
      </div>



      <van-dialog v-model="showLogout"
      :title="$t('message.profile_logout')"
      :confirmButtonText = "$t('message.confirm_btn')"
      :showCancelButton = "true"
      :cancelButtonText = "$t('message.cancel_btn')"
      :lazy-render="false"
      :close-on-click-overlay='true'>
        <div slot="title" class="page_flex">
          <span style="" class="page_flex_grow1 text_left dialog_title_style">{{$t('message.profile_logout')}}</span>
          <van-icon class="text_right dialog_close_style" @click="close_logout" name="cross" />
        </div>
        <div class="dialog_tips">{{$t('message.profile_logout')}}</div>


      </van-dialog>

    </div>

</template>

<script>
  import { Dialog } from 'vant';
  import { getUserInfo } from '@/api/user.js'
  import { doLogout } from '@/api/login.js';
  export default {
      name: 'UserPage',
      data() {
          return {
              credit:50,
              user_name:'',
              user_tel:'',
              user_invite_code:'',
              user_level:1,
              all_money:0,
              today_income:10.00,
              showLogout:false,
          }
      },
      created() {
        this.user_info();
      },
      computed: {

      },
      methods: {
        to_logout(){
          // this.showLogout = true;
          let that = this;

          Dialog.confirm({
            title: that.$t('message.profile_logout'),
            message: that.$t('message.my_logout'),
            confirmButtonText: that.$t('message.confirm_btn'),
            cancelButtonText: that.$t('message.cancel_btn'),
          })
            .then(() => {
              // on confirm
              that.do_logout();
            })
            .catch(() => {
              // on cancel
            });
        },
        close_logout(){
          this.showLogout = false;
        },
        async do_logout(){
          let that = this;
          const res = await doLogout();
          this.$toast(res.info);
          if(res.code == 0){
            setTimeout(function(){
              that.showLogout = false;
              console.log('do logout');
              that.$store.commit('user/setUserInfo',{});
              that.$router.replace('/home');
            },1000);
          }
        },
        async user_info(){
          let that = this;
          const res = await getUserInfo();
          // this.$toast(res.info);
          if(res.code == 0){
            this.all_money = res.balance;
            this.user_name = res.user_info.username;
            this.user_invite_code = res.user_info.invite_code;
            this.user_tel = res.user_info.tel;
          }
        },
        to_recharge(){
          this.$router.push('/recharge');
        },
        to_withdrawal(){
          this.$router.push('/withdrawal');
        },
        to_transfer(){
          this.$router.push('/transfer');
        },
        to_security(){
          this.$router.push('/security');
        },
        to_notify(){
          this.$router.push('/message_list');
        },
        to_invite(){
          this.$router.push({
            path:'/invite',
            query:{
              code:this.user_invite_code
            }
          });
        },
        to_account(pos){
          let title = '';
          switch (pos){
            case 0:
              title = this.$t('message.profile_exchange_account');
              break;
            case 1:
              title = this.$t('message.profile_contract_account');
              break;
            case 2:
              title = this.$t('message.profile_option_account');
              break;
          }
          this.$router.push({
            path: '/account',
            query: {
              pos: pos,
              title: title,
            }
          })
        }
      }
  }
</script>

<style lang="less" scoped>
  @import '../../css/pagestyle.less';

  .profile_user_info_block{
    // margin-top: 10px;
    padding: 10px;
    background: @primary_color_bg;
    margin: 10px;
    border-radius: 10px;
  }

  .profile_user_info_head{
    .page_flex();
    .page_flex_row();
  }

  .profile_user_info_head_left{
    margin-top: 8px;
    background: @op_primary_txt_color;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    //padding: 10px;

    img{
      width: 50px;
    }
  }

  .profile_user_info_head_content{
    .page_flex_grow1();
    padding: 10px;
  }

  .profile_user_info_head_end{
    margin-top: 8px;
    align-items: flex-end;
    img{
      width: 50px;
    }
  }

  .profile_user_info_middle{
    padding: 5px 0;
    .credit_text{
      color: @op_primary_txt_color;
      font-size: 20px;
      padding: 5px 0;
    }
  }

  .profile_user_info_head_content{
    color: @op_primary_txt_color;
    font-size: 20px;

    span{
      color: @high_primary_txt_color;
    }
  }

  .profile_user_info_end_item{
    margin-top: 20px;
    .page_flex();
    .page_flex_column();

    .title{
      color: @op_primary_txt_color;
    }

    .value{
      color: @high_primary_txt_color;
    }
  }

  .profile_user_action_block{
    padding: 0 10px 0 10px;

    .action_row{
      .page_flex();
      .page_flex_row();
      .page_flex_center();
      .text_center();
      padding-top: 20px;
    }

    .action_item{
      width: 33.333%;
    }

    .action_item_title{
      padding-top: 5px;
      font-size: 16px;
      color: @op_primary_txt_color;
    }

    img{
      background: @primary_color_bg;
      padding: 15px;
      border-radius: 10px;
      //box-shadow: @shadow;
      width:30px;
    }
  }

  .profile_user_logout{
    margin-top: 40px;
    .page_flex();
    .page_flex_center();
    .text_center();
  }


</style>
