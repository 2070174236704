<template>
  <div class="primary_page_bg all_page">
    <van-nav-bar class="login_navi_bg" title="" left-arrow @click-left="$router.go(-1)" fixed/>

    <div class="login_logo_block">
      <img class="login_logo" src="../../assets/logo_l.png"/>
    </div>
    <!-- <div class="login_cert_block"></div> -->
    <div style="position: absolute;bottom: 20px;width: 100%;">
      <div class="text_center" style="position: relative;">
        <div class="login_contact_block">
          <router-link :to="{path:'/customer_service'}" class="a_tag">{{$t('message.my_contact')}}</router-link>
        </div>

        <div style="width: 100%;margin-top: 20px;margin-bottom: 20px;">
          <div class="login_action_block">
            <div class="primary_btn btn_shape" @click="show_login_dialog=true">{{$t('message.login')}}</div>
          </div>
          <div class="login_action_block">
            <div class="register_btn btn_shape" @click="show_register_dialog=true" style="margin-top: 30px;">{{$t('message.register_title')}}</div>
          </div>

        </div>

        <div class="text_left login_margin_h" style="font-size: 10px;">
          <router-link :to="{path:'/register_note'}" class="a_tag">{{$t('message.zcxy')}}</router-link>
        </div>

        <div class="glory_block page_flex page_flex_center text_center" style="margin-top: 60px;">
          <div class="page_flex_grow1 glory_img_size_up">
            <img class="glory_img_size" src="@/assets/part1.png"/>
          </div>
          <div class="page_flex_grow1 glory_img_size_up">
            <img class="glory_img_size" src="@/assets/part2.png"/>
          </div>
          <div class="page_flex_grow1 glory_img_size_up">
            <img class="glory_img_size" src="@/assets/part3.png"/>
          </div>
        </div>

        <div class="glory_block page_flex page_flex_center text_center" style="margin-top: 30px;">
          <div class="page_flex_grow1 glory_img_size_up">
            <img class="glory_img_size" src="@/assets/part4.png"/>
          </div>
          <div class="page_flex_grow1 glory_img_size_up">
            <img class="glory_img_size" src="@/assets/part5.png"/>
          </div>
          <div class="page_flex_grow1 glory_img_size_up">
            <img class="glory_img_size" src="@/assets/part6.png"/>
          </div>
        </div>
      </div>
    </div>

    <van-dialog v-model="show_login_dialog"
    :title="$t('message.index_login_title')"
    :show-confirm-button='false'
    :lazy-render="false"
    :close-on-click-overlay='true'>
      <div slot="title" class="page_flex">
        <span style="" class="page_flex_grow1 text_left dialog_title_style">{{$t('message.index_login_title')}}</span>
        <van-icon class="text_right dialog_close_style" @click="close_login" name="cross" />
      </div>
      <form>
        <div class="dialog_content_block">
          <input class="input_field" :placeholder="$t('message.login_phone_title')" v-model="login_name"/>
          <input class="input_field" type="password" autocomplete="new-password" :placeholder="$t('message.login_pass_title')" v-model="login_pwd"/>
          <div style="width: 100%;" class="page_flex">
            <router-link style="padding: 5px 10px;" class="text_right page_flex_grow1" :to="{path:'/customer_service'}">{{$t('message.forgot')}}</router-link>
          </div>

          <div class="login_dialog_action_block" style="margin-top: 10px;">
            <div class="primary_btn btn_shape" style="width: 100%;" @click="to_login">{{$t('message.login')}}</div>
            <div class="" @click="show_register" style="padding-top: 20px;padding-bottom: 10px;">{{$t('message.index_reg_title')}}</div>
          </div>
        </div>
      </form>
    </van-dialog>


    <van-dialog v-model="show_register_dialog"
    :title="$t('message.register_title')"
    :show-confirm-button='false'
    :lazy-render="false"
    :close-on-click-overlay='true'>
      <div slot="title" class="page_flex">
        <span style="" class="page_flex_grow1 text_left dialog_title_style">{{$t('message.register_title')}}</span>
        <van-icon class="text_right dialog_close_style" @click="close_register" name="cross" />
      </div>
      <form>
        <div class="dialog_content_block">
          <input class="input_field" :placeholder="$t('message.login_name_title')" v-model="register_name"/>
          <input class="input_field" :placeholder="$t('message.bind_bank_tel')" v-model="register_tel"/>
          <input class="input_field" type="password" autocomplete="new-password"
          :placeholder="$t('message.login_pass_title')" v-model="register_login_pwd"/>
          <input class="input_field" type="password" autocomplete="new-password"
          :placeholder="$t('message.withdrawal_pwd_title')" v-model="withdraw_pwd"/>
          <input class="input_field" :placeholder="$t('message.login_qr_title')" v-model="register_invite_code"/>
          <div style="width: 100%;" class="page_flex">
            <van-checkbox v-model="register_aggreement">
              <router-link style="padding: 5px 10px;" class="page_flex_grow1" :to="{path:'/confPage'}">{{$t('message.zcxy')}}</router-link>
            </van-checkbox>
          </div>

          <div class="page_flex page_flex_center page_flex_column text_center" style="margin-top: 10px;">
            <div class="primary_btn btn_shape" style="width:100%" @click="to_register">{{$t('message.register_title')}}</div>
            <div class="" @click="show_login" style="padding-top: 20px;padding-bottom: 10px;">{{$t('message.register_to_login')}}</div>
          </div>
        </div>
      </form>

    </van-dialog>

  </div>

</template>

<script>
  import md5 from 'js-md5';
  import { doLogin,doRegister } from '@/api/login.js';
  import request from '@/utils/requests';
  export default {
      data() {
          return {
              show_login_dialog: false,
              show_register_dialog: false,
              login_name: '',
              login_pwd: '',
              baseURL: process.env.VUE_APP_BASE_URL,
              register_tel: '',
              register_name:'',
              register_login_pwd:'',
              withdraw_pwd:'',
              register_invite_code:'',
              register_aggreement:true,

          }

      },
      name: 'LoginPage',

      methods: {
        show_register(){
          this.show_login_dialog = false;
          this.show_register_dialog = true;
        },
        show_login(){
          this.show_login_dialog = true;
          this.show_register_dialog = false;
        },
        close_login(){
          this.show_login_dialog = false;
        },
        close_register(){
          this.show_register_dialog = false;
        },
        // to_contact_us(){
        //   return this.$router.push({path:'/cart'});
        // },
        async to_login(){
          let that = this;
          const res_json = await doLogin(this.login_name,this.login_pwd);

          if (res_json.code == 0){
            this.$store.commit('user/setUserInfo',res_json);
            this.$forceUpdate();
            //console.log('token(login):',this.$store.getters['user/token']);
            this.$toast(that.$t('message.sign_in_success'));
            setTimeout(function(){
              that.show_login_dialog = false;
              //console.log('token(login111):',that.$store.getters['user/token']);
              // that.$router.replace('/');
              window.history.back();
            },1000);
          }
          else if(res_json.code == 100){
            console.log('format error!');
            this.$toast(that.$t('message.error_param'));
          }
          else{
            console.log('login error 99');
            this.$store.commit('user/setUserInfo',{});
            console.log('info:' + res_json.info);
            this.$toast(res_json.info);
          }

        },
        async to_register(){
          let that = this;
          const res_json = await doRegister(this.register_tel,this.register_name,this.register_login_pwd,this.withdraw_pwd,this.register_invite_code);
          if (res_json.code == 0){
            this.$toast(that.$t('message.reg_ok'));
            setTimeout(function(){
              that.show_register_dialog = false;
              that.show_login_dialog = true;
            },1000);
          }
          else if(res_json.code == 100){
            console.log('format error!');
            this.$toast(that.$t('message.error_param'));
          }
          else{
            console.log('info:' + res_json.info);
            this.$toast(res_json.info);
          }
        },

        validFn() {
            if (!/^1[3-9]\d{9}$/.test(this.register_tel)) {
                this.$toast("Plz enter correct phone num")
                return false
            }
            // if (!/^\w{4}$/.test(this.picCode)) {
            //     this.$toast("Plz enter correct pic num")
            //     return false
            // }
            return true
        },

      },
      async created() {
          this.show_login();
      },
      destroyed() {
      },
  }
</script>

<style lang="less" scoped>
@import '../../css/pagestyle.less';

.login_margin_h{
  margin-left: 10px;
  margin-right: 10px;
}

.login_logo_block{
	padding-top: 51px;//calc((@screen_high - 708px) / 2);
	.text_center();
}

.login_action_block{
  // margin: 20px;
  .page_flex();
  .page_flex_row();
  .page_flex_center();
  .text_center();
}

.login_cert_block{
  margin-top: 150px;
}

.glory_block{
  width: 100%;
}

.glory_img_size_up{
  margin: 0 20px;
}

.glory_img_size{
  width: 100%;
  max-width: 100px;
}

.dialog_content_block{
  margin: 5px 10px;
}

.login_dialog_action_block{
  .page_flex();
  .page_flex_center();
  .page_flex_column();
  .text_center();
}


.register_btn{
  background: @sub_btn;
	color: @op_primary_txt_color;
}

.container {
    padding: 49px 29px;

    .title {
        margin-bottom: 20px;

        h3 {
            font-size: 26px;
            font-weight: bold;
            color: #ecb53c;
        }

        p {
            line-height: 40px;
            font-size: 14px;
            color: #b8b8b8;
        }
    }

    .form-item {
        border-bottom: 1px solid #f3f1f2;
        padding: 8px;
        margin-bottom: 14px;
        display: flex;
        align-items: center;

        .inp {
            display: block;
            border: none;
            outline: none;
            height: 32px;
            font-size: 14px;
            flex: 1;
        }

        img {
            width: 94px;
            height: 31px;
        }

        button {
            height: 31px;
            border: none;
            font-size: 13px;
            color: #cea26a;
            background-color: transparent;
            padding-right: 9px;
        }
    }

    .login-btn {
        width: 100%;
        height: 42px;
        margin-top: 39px;
        background: linear-gradient(90deg, #ecb53c, #ff9211);
        color: #fff;
        border-radius: 39px;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .1);
        letter-spacing: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

::v-deep a{
  color: @op_primary_txt_color;
}

</style>
