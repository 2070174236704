<template>
    <div class="quote_index all_page">
      <van-nav-bar :title="target + '/' + base" left-arrow @click-left="$router.go(-1)" fixed />
      <div class="ticker_content second_page_margin">
        <div class="left">
          <div class="price">
            <TickerText :text_num="ticker.price"></TickerText>
          </div>
          <div class="percent">
            <TickerPercent :text_num="ticker.percent"></TickerPercent>
          </div>
        </div>
        <div class="right">
          <div class="high">
            <div class="title">{{$t('message.home_market_quote_high')}}</div>
            <div class="value" v-html="ticker.high.toFixed(5)"></div>
          </div>
          <div class="low">
            <div class="title">{{$t('message.home_market_quote_low')}}</div>
            <div class="value" v-html="ticker.low.toFixed(5)"></div>
          </div>
          <div class="quantity">
            <div class="title">{{$t('message.home_market_quote_quantity')}}</div>
            <div class="value" v-html="ticker.quantity.toFixed(5)"></div>
          </div>
        </div>
      </div>

      <div class="kline_content">
        <Vue-kline :klineParams="klineParams" :klineData="klineData" ref="callMethods" @refreshKlineData="refreshKlineData"></Vue-kline>
      </div>

      <div class="order_list" style="margin-top: 10px;">
        <div class="order_list_big_title">
          {{$t('message.home_market_quote_order_title')}}
        </div>
        <div class="order_title">
          <div class="item_time">{{$t('message.home_market_quote_order_title_time')}}</div>
          <div class="item_direct">{{$t('message.home_market_quote_order_title_direct')}}</div>
          <div class="item_price">{{$t('message.home_market_quote_order_title_price')}}</div>
          <div class="item_vol">{{$t('message.home_market_quote_order_title_number')}}</div>
        </div>
        <div class="order_list_content">
          <QuoteOrderItem v-for="(item,index) in order_list" :key="index" :time="item.time"
                          :type="item.direction" :price="item.price" :vol="item.number">
          </QuoteOrderItem>
        </div>
      </div>

      <div style="position: fixed;bottom: 0;width: 100%;z-index: 200;">
        <div class="operation_block">
          <van-button class="buy_btn" :text="$t('message.home_market_quote_order_direct1')" @click="to_buy"></van-button>
          <van-button class="sell_btn" :text="$t('message.home_market_quote_order_direct2')" @click="to_sell"></van-button>
        </div>
      </div>

    </div>
</template>

<script>
import axios from 'axios'
  // import { init, dispose, registerLocale } from 'klinecharts'
import { getHomeData } from '@/api/home';
  // import { KLineChartPro, loadLocales } from '@klinecharts/pro'
  // import { CustomDatafeed } from '@/api/datafeed'
  // import '@klinecharts/pro/dist/klinecharts-pro.css'
import VueKline from 'vue-kline/src/kline/kline';
import QuoteOrderItem from "@/components/QuoteOrderItem";
import TickerText from "@/components/TickerText";
import TickerPercent from "@/components/TickerPercent";
import { klineData,tickerQuote,tradeInfo } from '@/api/quote';

export default {
    name: 'QuotePage',

  components: {
    VueKline,
    QuoteOrderItem,
    TickerText,
    TickerPercent
  },
    // components: {
    //     GoodsItem
    // },
    data() {
        return {
          pos:2,//pos:1exchange,2options,3contract
          levelList: [],
          user_info: {},
          target:'',
          base:'',
          ticker: {
            price:0,percent:0,high:0,low:0,quantity:0
          },
          klineParams: {
            width: window.innerWidth-2,
            height: 500,
            theme: "dark",
            language: "en-us",
            ranges: ["line", "1m", "5m", "15m", "30m", "1h", "1d", "1w"],
            symbol: "BTC",
            symbolName: "BTC/USD",
            intervalTime: 5000,
            depthWidth: 50,
            count: 1,
          },
          klineData: {},
          symbol_text:'',
          order_list:[],
        }
    },
  methods:{

    async get_kline_data($target,$base,$type,$to){
      const kline_data = await klineData($target,$base,$type,$to)
      console.log(kline_data);
      if(kline_data.code == 0){
        this.klineData = kline_data.data;      // 把返回数据赋值到上面的 klineData,
        this.$refs.callMethods.kline.chartMgr.getChart().updateDataAndDisplay(kline_data.data.data.lines); //强制更改缓存中的lines值,防止显示不同步
      }
    },

    async get_ticker_data($target,$base){
      const ticker_data = await tickerQuote($target,$base)
      console.log(ticker_data);
      if(ticker_data.code == 0){
        this.ticker.price = ticker_data.ticker.price;
        this.ticker.high = ticker_data.ticker.high;
        this.ticker.low = ticker_data.ticker.low;
        this.ticker.quantity = ticker_data.ticker.amount;
        this.ticker.percent = ticker_data.ticker.percent;
      }
    },

    async get_trade_data($target,$base){
      const trade_data = await tradeInfo($target,$base)
      console.log(trade_data);
      if(trade_data.code == 0){
        this.order_list = trade_data.trade;
      }
    },

    // requestData(){
    //   /*测试用的  请求服务器请用下面的*/
    //   // this.klineData = data;
    //   // this.$refs.callMethods.kline.chartMgr.getChart().updateDataAndDisplay(data.data.lines);


    //   axios.request({
    //     url: "http://exc_server_new2.localhost/api/ticker/klinev2",             //请求地址
    //     method: "POST"
    //   }).then(ret => {
    //     this.klineData = ret.data;      // 把返回数据赋值到上面的 klineData,
    //     this.$refs.callMethods.kline.chartMgr.getChart().updateDataAndDisplay(ret.data.data.lines); //强制更改缓存中的lines值,防止显示不同步
    //   });
    // },
    // requestRealTimeData(){              //方法名任意取
    //   /*测试用的  请求服务器请用下面的*/
    //   // this.klineData = data;
    //   // this.$refs.callMethods.kline.chartMgr.getChart().updateDataAndDisplay(data.data.lines);


    //   axios.request({
    //     url: "http://exc_server_new2.localhost/api/ticker/realtimelinev2",             //请求地址
    //     method: "POST"
    //   }).then(ret => {
    //     this.klineData = ret.data;      // 把返回数据赋值到上面的 klineData,
    //     this.$refs.callMethods.kline.chartMgr.getChart().updateDataAndDisplay(ret.data.data.lines); //强制更改缓存中的lines值,防止显示不同步
    //   });
    // },
    refreshKlineData(option){  //你点击页面上的周期会触发这个方法
      // this.requestData();
      // return;

      let period = '1day';
      let cur_time = Date.now();

      console.log("kline time:"+option);

      if (option===900000){   //如果时间等于15分钟
        //this.requestData();
        period = '15min';
      }else if(option===300000){//如果5分钟
        period = '5min';
      }else if(option === 60000){//分时
        // this.requestRealTimeData();
        period = '1min';
      }else if(option === 1800000){//30分钟
        period = '30min';
      }else if(option === 3600000){//60分钟
        period = '60min';
      }else if(option === 14400000){//4小时
        period = '4hour';
      }else if(option === 86400000){//1day
        period = '1day';
      }else if(option === 86400000){//1day
        period = '1day';
      }else if(option === 604800000){//1week
        period = '1week';
      }else{
        period = '1day';
      }

      this.get_kline_data(this.target,this.base,period,cur_time);
    },
    onLangChangeFunc(lang){
      console.log("onLangChangeFunc:"+lang);
    },
    to_buy(){
      this.$store.commit('symbol/setSymbolInfo',{target:this.target,base:this.base,direct:2});
      switch (this.pos){
        case '1':
          this.$router.replace('/exchange');
          break;
        case '2':
          this.$router.replace('/options');
          break;
        case '3':
          this.$router.replace('/contracts');
          break;
      }

    },
    to_sell(){
      this.$store.commit('symbol/setSymbolInfo',{target:this.target,base:this.base,direct:1});
      switch (this.pos){
        case '1':
          this.$router.replace('/exchange');
          break;
        case '2':
          this.$router.replace('/options');
          break;
        case '3':
          this.$router.replace('/contracts');
          break;
      }
    }
  },
    async created() {
      this.target = this.$route.query.target;
      this.base = this.$route.query.base;
      this.pos = this.$route.query.pos;

        const home_data = await getHomeData()
        // console.log(home_data);
        // if(home_data.code == 99){//
        //   this.$store.commit('user/setUserInfo',{});
        //   this.$router.replace('/');
        //   return;
        // }
    },
    mounted(){
      //this.refreshKlineData(900000);// 进入页面时执行,默认聚合时间900000毫秒(15分钟)
      this.get_ticker_data(this.target,this.base);
      this.get_trade_data(this.target,this.base);

    },
    unmounted(){
      // delete chart
        //dispose('chart')
    }
}
</script>

<style lang="less" scoped>
@import '../../css/pagestyle.less';
.van-nav-bar__content{
  height: 80px !important;
  // background: rgba(0, 0, 0, 1);
}

.quote_index {
  min-height: 100vh;
  background: rgba(0, 0, 0, 1);
}

.quote_index ::v-deep .van-nav-bar{
  background-color: @navigation_normal_bg;
  color: @navigation_normal_color;

  .van-nav-bar__title{
    color: @navigation_normal_color;
  }

  ::after{
    all: unset !important;
  }
}

.kline_content{
  height: 500px;
}

::v-deep .chart_tabbar,
::v-deep .chart_toolbar{
  width: 100%; /* 容器宽度 */
  overflow-x: auto; /* 横向滚动条 */

  -ms-overflow-style: none;  /* IE 和 Edge */
  scrollbar-width: none;  /* Firefox */

  ::-webkit-scrollbar{
    display: none;
  }
}

::v-deep .klinecharts-pro-period-bar{
  width: 100%; /* 容器宽度 */
  overflow-x: auto; /* 横向滚动条 */

  -ms-overflow-style: none;  /* IE 和 Edge */
  scrollbar-width: none;  /* Firefox */

  ::-webkit-scrollbar{
    display: none;
  }
}

::v-deep .van-nav-bar--fixed,
::v-deep .van-nav-bar__content{
  z-index: 200;
}

.order_list_big_title{
  color: @white_bg;
  padding: 20px 0;
  font-size: 18px;
}

.order_list{
  padding: 0 10px 66px 10px;
}

.order_title{
  .page_flex();
  .page_flex_row();
  color: @white_bg;

  .item_time{
    width: 20%;
    text-align: left;
  }
  .item_direct{
    width: 20%;
    text-align: center;
  }
  .item_price{
    width: 30%;
    text-align: center;
  }
  .item_vol{
    width: 30%;
    text-align: right;
  }
}

.operation_block{
  background: @primary_bg;
  .page_flex();
  .page_flex_row();

  .buy_btn,
  .sell_btn{
    width: 50%;
  }
}

.ticker_content{
  .page_flex();
  .page_flex_row;
  color: @white_bg;

  .left{
    width: 40%;
    padding: 10px;
    text-align: center;
    .page_flex_column();

    .percent{
      line-height: 32px;
    }
    .price{
      line-height: 32px;
      font-size: 20px;
    }
  }

  .right{
    width: 60%;
    .page_flex_column();

    .high,
    .low,
    .quantity{
      padding: 5px 10px;
      .page_flex();
      .page_flex_row();

      .title{
        text-align: left;
      }

      .value{
        text-align: right;
        .page_flex_grow1();
      }
    }
  }
}
</style>
